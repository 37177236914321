<template>
  <div class="wrapper static-article">
    <row>
      <column
        xs="12"
        md="2">
      </column>
      <column
        xs="12"
        md="8">
        <h1 class="ff-flama fs-xxl fw-light tt-uppercase mb-5">
          关于我们
        </h1>
        <div class="article-body clearfix mb-3">
          <h1>关于使用微信推送新闻的通知</h1>
          <img
            :src="`${$cdn.resource(`gfx/upstream/ups-weixin.jpg`)}`"
            class="w-100"
            alt="">
          <p>
            为了让我们的客户和读者更加方便阅读我们的新闻报道，本公司将从6月1日起通过社交媒体平台微信(WeChat)每日推送最新的新闻报道、行业动态、活动资讯、最新促销等信息。
          </p>
          <p>
            请关注我们的微信公众号，了解最新精彩内容。
          </p>
          <p>
            关注我们： 在微信内搜索公众号（Upstream《上游》周报）加公众微信号;
          </p>
          <p>
            或扫描二维码关注公众号:
          </p>
        </div>

        <div class="text-center">
          <img
            :src="`${$cdn.resource(`gfx/upstream/ups-wechat.jpg`)}`"
            class="w-100"
            style="max-width: 300px"
            alt="">
        </div>

        <div class="article-body clearfix mb-3">
          <p>
            如有其他问题，请填写表格与我们取得联系:
          </p>

          <component
            :is="'script'"
            v-html="formScript"/>

          <hr>
          <h2>关于我们</h2>
          <p>
            作为国际石油天然气业界颇具声名的专业报刊，《上游报》---UPSTREAM拥有一支经验丰富、高度敬业的记者队伍。他们从世界各地为94个国家的读者及时提供高品质英文新闻。报纸拥有37000多名读者，遍布106个国家，133000多名网站单独用户，网站www.upstreamonline.com月页面浏览次数超过160万次。
          </p>
          <p>
            《上游报》的报道涉及全球整个石油及天然气及液化天然气上游产业，以热点聚焦、评论、人物特写、市场、以及油气田工程的设计、采购和建造招投标消息等形式突出报道产业动态和和大型公司新闻。保持新闻的独立性和追求独家消息是《上游报》的一贯采编方针。
          </p>
          <p>
            周报由挪威的NHST媒体集团出版，每周五刊出。报纸同时通过网站24小时不间断从亚洲，欧洲和美洲报导行业实时和突发新闻。报纸网站具有强大的搜索功能。只要输入主题词，读者便会得到某项目和事件的背景和来龙去脉。读者还可以通过APP软件从手机及时读到每周的纸质报纸。报纸的中文团队每周从报纸选出30条中国读者感兴趣的新闻并翻译成中文于每周一通过报纸网站发布和微信公众号推送。上游报还不定期出版专刊，深度报导读者关心上游产业热点和国家聚焦。由于的知名度和专业性，《上游报》是诸多世界知名展会如“世界石油大会”的指定官方日报。
          </p>
        </div>
      </column>
    </row>
  </div>
</template>

<script>
import { EmbedMixin, AssetMixin } from 'global-mixin';
import { Page } from 'global-components';

export default {
  name: 'chinese-page',
  extends: Page,
  mixins: [EmbedMixin, AssetMixin],
  data() {
    return {
      formScript: ''
    };
  },
  embed(head) {
    head.script('//js.hsforms.net/forms/v2.js', {
      charset: 'utf-8'
    });
  },
  async assets() {
    this.formScript = `hbspt.forms.create({portalId: "1545457", formId: "0d4d0940-4e32-465f-8c49-cb220fb4667a"});`;
    this.$nextTick();
  }
};
</script>
